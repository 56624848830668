.adminList__wrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  width: 100%;
}

.adminList__mainTitle {
  color: var(--neutral-black, #0a141e);
  /* Text/Subtitle/16M */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.adminList__content-wrapper {
  width: 100%;
}

.link__disabled {
  color: #5c6070 !important;
  cursor: auto;
}

.adminList__table {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.adminList__thead {
  background-color: #f6f9fd;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #e4e5e9;
  padding: 0px 8px;
  padding-left: 24px;
  width: 100%;
}

.adminList-headerC {
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 24px;
  padding: 12px 0px;
  display: grid;
  grid-template-columns: 10% 40% auto;
}

.adminList-columnTitle {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #5c6070;
  color: black;
}

.adminList__body-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.adminList__body-tr {
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  gap: 24px;
  min-height: 32px;
  padding: 8px;
  padding-left: 24px;
  width: 100%;
  border: 1px solid #e4e5e8;
  display: grid;
  grid-template-columns: 10% 40% auto;
}

.adminList-row {
  display: flex;
  align-items: center;
  padding: 0px;
  height: 57px;
  border-radius: 8px;
  width: 100%;
}

.adminList__rowData {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #2f2f2f;
}

.adminList__btn-action {
  width: 100%;
}

.adminList__btn-action-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

@media (max-width: 768px) {
  .adminList-headerC,
  .adminList__body-tr {
    grid-template-columns: 10% 20% auto;
  }
}

.adminList__editableRow-input {
  width: 100%;
  padding: 8px 0px 8px 4px;
  margin: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #2f2f2f;
}

.adminList__btn-addNew {
  margin-top: 48px;
}